import styled from "styled-components";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import { UPLOAD_CDN, DEFAULT_DOC_URL } from "config/api";
const Card = ({
  media,
  isSelected,
  small,
  isMany = false,
  onClick = () => {},
}) => {
  const { t } = useTranslation();
  const [, copy] = useCopyToClipboard();
  function handleClick() {
    onClick(media);
    copy(UPLOAD_CDN + media.filename);
    if (!isMany) {
      toast.success(t("copied"));
    }
  }
  return (
    <Container onClick={handleClick} isSelected={isSelected}>
      <MediaContainer
        small={small}
        mimetype={media.mimetype}
        url={UPLOAD_CDN + media.filename}
      ></MediaContainer>
      <MetaContainer>{media.originalname}</MetaContainer>
    </Container>
  );
};

const MediaContainer = ({ mimetype, url, small }) => {
  if (isImage(mimetype)) {
    return <Image loading="lazy" src={url} alt="img" small={ small } />;
  }

  if (isVideo(mimetype)) {
    return (
      <Video loading="lazy" src={url} controls>
        <source src={url} type={mimetype} />
      </Video>
    );
  }

  return <Image src={DEFAULT_DOC_URL} alt="img" />;
};

const isImage = (mimetype) => mimetype.indexOf("image") > -1;
const isVideo = (mimetype) => mimetype.indexOf("video") > -1;

export default Card;

const Image = styled.img`
  width: 100%;
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
  height: ${props => props.small ? '150px' : '250px'};
  object-fit: cover;
  background-color: ${(props) => props.theme.colors.light.sidebar};
`;

const Video = styled.video`
  width: 100%;
  
  display: block;
  margin-bottom: 5px;

  cursor: pointer;
`;

const Container = styled.div`

  position: relative;
  transition: .05s all ;
  border: ${(props) =>
    props.isSelected
      ? "6px solid " + props.theme.colors.light.sidebar
      : "none"};
`;

const MetaContainer = styled.div`
  position: absolute;
  top: 0;
  padding: 15px;
  padding-top:5px ;
  text-align: center;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height:30px;
  font-family: ${(props) => props.theme.font.body};
  font-weight: 800;
  color: rgba(0, 0, 0, 0.3);
  background-color: white;
  box-shadow: ${(p) => p.theme.shadows.md};

  cursor: pointer;
  :hover {
    color: rgba(0, 0, 0, 0.8);
  }
`;
