import { useMemo } from "react";
import { NavBar, NavBarElement, StyledActiveLink, NavBarTitle } from "./Navbar";
import { Spacing } from "components/Layout/Spacing";
import { useTranslation } from "react-i18next";
import groupByKey from "lib/groupByKey";

const Navigation = ({ items }) => {
  const { t } = useTranslation();
  const routes = useMemo(() => items.filter((f) => !f.hide), [items]);
  const withParentRoutes = useMemo(
    () => routes.filter((f) => !!f.parent),
    [routes]
  );
  const withoutParentRoutes = useMemo(
    () => routes.filter((f) => !f.parent),
    [routes]
  );
  const groupedWithParent = useMemo(
    () => Object.entries(groupByKey(withParentRoutes, "parent")),
    [withParentRoutes]
  );

  return (
    <>
    <nav>
      <NavBar>
        {groupedWithParent &&
          groupedWithParent.map((item, k) => {
            return item && Array.isArray(item[1]) ? (
              <div key={item[1] + k}>
                <NavBarTitle>{t(item[0])}</NavBarTitle>
                {item[1].map((i) => (
                  <NavBarElement key={i.link}>
                    <StyledActiveLink key={i.link} to={i.link}>
                      {t(i.menuLabel)}
                    </StyledActiveLink>
                  </NavBarElement>
                ))}
              </div>
            ) : null;
          })}

        <Spacing bottom="lg" />
        {withoutParentRoutes.map((item) => (
          <NavBarElement key={item.link} noparent>
            <StyledActiveLink key={item.link} to={item.link}>
              {t(item.menuLabel)}
            </StyledActiveLink>
          </NavBarElement>
        ))}
      </NavBar>
      </nav>
      </>
  );
};

export default Navigation;
