import create from 'zustand'
import { persist } from 'zustand/middleware'
import omit from 'lodash-es/omit'
import {  LOGIN_INFOS } from 'config/localStorage'

const useUser = create(
  persist(
    (set, get) => ({
      user: null,
      setUser: (data) => set({ user: data }),
      deleteUser: () => set((state) => omit(state, ['user']), true),
    }),
    {
      name: LOGIN_INFOS,
      getStorage: () => localStorage,
    }, {

    onRehydrateStorage: (state) => {
      console.log('hydration starts')
    },
  }

  )
)

export default useUser
