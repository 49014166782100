import { useState } from "react";

function useCopyToClipboard() {
  const [copiedText, setCopiedText] = useState(null);

  const copy = async (text) => {
    if (!navigator?.clipboard) {
      console.warn("Clipboard not supported");
      return false;
    }
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      console.warn("Copy failed", error);
      setCopiedText(null);
      return false;
    }
  };

  return [copiedText, copy];
}

export default useCopyToClipboard;

//credit https://usehooks-ts.com/react-hook/use-copy-to-clipboard

/**
 * Usage
 * 
 * import React from 'react'
 * import { useCopyToClipboard } from '#hooks/useCopyToClipboard'
 * export default function Component() {
 * const [value, copy] = useCopyToClipboard()
 * return (
 * <>
 * <h1>Click to copy:</h1>
 * <div style={{ display: 'flex' }}>
 * <button onClick={() => copy('A')}>A</button>
 * <button onClick={() => copy('B')}>B</button>
 * <button onClick={() => copy('C')}>C</button>
 * </div>
 * <p>Copied value: {value ?? 'Nothing is copied yet!'}</p>
 * </>
 *  )
}

 */
