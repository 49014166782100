import {  fetchit } from 'lib'

import {
    API_USERS
} from 'config/api'


export function createUser(data, accessToken) {
    return fetchit(API_USERS, data, 'POST', accessToken)
}

export function editUser(data, userId, accessToken) {
    return fetchit(API_USERS+'/'+userId, data, 'PATCH', accessToken)
}

export function getUsers({ placeId, accessToken }) {
    if(!placeId) return
    return fetchit(API_USERS+'/?role=guest&place='+placeId, null, 'GET', accessToken)
}

export function deleteUser(userId, accessToken) {
    if(!userId) return
    return fetchit(API_USERS+'/'+userId, null, 'DELETE', accessToken)
}
