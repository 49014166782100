import PropTypes from "prop-types";
import { PaginationContainer, PaginationList } from "./Pagination.style";

export default function List({ onChange, current, total }) {
  if (!(total >= 0 && current >= 0)) {
    return <></>
  }

  return (
    <nav role="navigation" aria-label="Pagination">
      <PaginationContainer>
        {Array.from(Array(parseInt(total)), (_, k) => {
          return (
            <PaginationList
              key={k + "PaginationList"}
              current={current === k + 1}
            >
              <a
                href={"#/"}
                aria-current="true"
                onClick={() => onChange(k + 1)}
              >
                {k + 1}
              </a>
            </PaginationList>
          );
        })}
      </PaginationContainer>
    </nav>
  );
}

List.propTypes = {
  onChange: PropTypes.func,
  current: PropTypes.number,
  total: PropTypes.number,
};
