import Map, { Marker } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import PropTypes from "prop-types";
import { MAPBOX_ACCESS_TOKEN } from "config/map";
import "mapbox-gl/dist/mapbox-gl.css";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export default function MapComponent({
  longitude,
  latitude,
  zoom = 13,
  height = 400,
}) {
  return (
    <Map
      mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
      initialViewState={{
        longitude,
        latitude,
        zoom,
      }}
      style={{ width: "100%", height }}
      mapStyle="mapbox://styles/comepic/cjkvz10jv02rp2ru9bd5xa9eh"
    >
      <Marker
        longitude={longitude}
        latitude={latitude}
        anchor="bottom"
      ></Marker>
    </Map>
  );
}

MapComponent.propTypes = {
  longitude: PropTypes.string.isRequired,
  latitude: PropTypes.string.isRequired,
  zoom: PropTypes.string,
  height: PropTypes.number,
};
