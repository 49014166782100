import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import useUser from "hooks/useUser";
import useLocation from "hooks/useLocation";
import MembersForm from "./MembersForm";
import roles from "config/roles";
import { Spacing } from "components/Layout/Spacing";
import List from "components/List";
import { Button } from "components/Form";
import {  FixedBottom } from "components/Layout/Styles";
import Modal from "components/Layout/Modal";
import {
  addStaffToPlace,
  removeStaffFromPlace,
  getPlaceById,
} from "./services";

export default function App() {
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { access } = useUser((state) => state.user);
  const selectedLocation = useLocation((state) => state.selected);

  const currentPlace = useQuery(selectedLocation, () =>
    getPlaceById({
      placeId: selectedLocation,
      accessToken: access.token,
    })
  );

  const onNewMutation = useMutation(
    (data) => addStaffToPlace(selectedLocation, data, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(selectedLocation);
        setResetForm(true);
        setOverlayVisible(false);
      },
      onError: () => {
        toast.error("An error occured");
      },
    }
  );

  const onDeleteMutation = useMutation(
    (id) => removeStaffFromPlace(selectedLocation, id, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(selectedLocation);
      },
      onError: (err) => {
        toast.error(t("update.error"));
      },
    }
  );

  const onNew = async ({ email, name, role=roles['staff'] }) => {
    onNewMutation.mutate({ email, name, role });
  };

  const onDelete = (id) => {
    onDeleteMutation.mutate(id);
  };


  function notEditable() {
    toast.error("Edit members feature is disabled.");
  }



  return (
    <div>
      <FixedBottom>
        <Button onClick={() => setOverlayVisible(true)}>
          {t("form.addNewMember")}
        </Button>
      </FixedBottom>

      <Spacing top="md" />
      <Modal visible={ overlayVisible } setVisible={setOverlayVisible}>
      <MembersForm
              onSubmit={onNew}
              buttonLabel={t("form.save")}
              resetForm={resetForm}
            />
      </Modal>
     

      {currentPlace?.data?.staff && (
        <List
          items={currentPlace.data.staff}
          onDeleteBtn={onDelete}
          onClick={notEditable}
        />
      )}
    </div>
  );
}
