import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { login } from "./services";
import { REDIRECT_PATH } from "config/paths";
import useUser from "hooks/useUser";
import { Title, Text } from "components/Typography";
import { Button, Input } from "components/Form";
import { Spacing } from "components/Layout/Spacing";
import { Row, Column } from "components/Layout/Styles";
import { Container, LoginBox, Wrapper, RightSide, Error } from "./Style";
import LottieLogin from "./LottieLogin";
import LottieCheckEmail from "./LottieCheckEmail";

export default function LoginComponent() {
  const navigate = useNavigate();
  const user = useUser((state) => state.user);
  const [email, setEmail] = useState();
  const mutation = useMutation(() => login(email));

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!!email) {
      mutation.mutate();
    } else {
      alert("Errors");
    }
  };
  
  useEffect(() => {
    if (mutation.isSuccess) {
      setTimeout(() => {
        navigate(REDIRECT_PATH)
      }, 10*1000)
    }
  },[mutation.isSuccess, navigate])

  const handleEmailInput = (e) => setEmail(e.target.value);

  if (!!user?.user?.email) {
    return <Navigate to={REDIRECT_PATH} replace />;
  }

  return (
    <Row>
      <Column>
        <Container>
          <LoginBox>
            <Title>Log in</Title>
            <form onSubmit={handleSubmit}>
              <Wrapper>
                <Input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  onChange={handleEmailInput}
                  disabled={mutation.isSuccess}
                />
              </Wrapper>
              <Spacing bottom="sm" />
              <Wrapper>
                <Button type="submit" disabled={!email || mutation.isSuccess}>
                  {!mutation.isSuccess &&
                  !mutation.isError &&
                  !mutation.isLoading
                    ? "Continue"
                    : null}
                  {mutation.isSuccess ? "Check your email" : null}
                  {mutation.isError ? "Try again" : null}
                  {mutation.isLoading ? "Loading..." : null}
                </Button>
                <Spacing bottom="md" />
                <Text sm>
                  By clicking continue, you agree to UPOUI
                  SARL{" "}
                  <a href="https://concierge.fra1.digitaloceanspaces.com/26c50f31-1fcf-48f9-ab4e-d804409d7555.pdf">
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a href="https://concierge.fra1.digitaloceanspaces.com/719b740e-c1fe-4d6d-a0ff-8b2e365e69f2.pdf">
                    {" "}
                    Privacy Policy.
                  </a>
                </Text>
                <Spacing bottom="sm" />
                {mutation.isError ? (
                  <Error>❌🤓 {mutation.error.message}</Error>
                ) : null}
              </Wrapper>
            </form>
          </LoginBox>
        </Container>
      </Column>
      <Column>
        <RightSide alert={mutation.isError && !mutation.isSuccess}>
          {
            mutation.isSuccess ? <LottieCheckEmail /> : <LottieLogin /> 
          }
          <LottieLogin />
        </RightSide>
      </Column>
    </Row>
  );
}
