import styled from 'styled-components';

/**
 * Adds margins to UI, using theme spacing options
 *
 * @param {string} top
 * @param {string} right
 * @param {string} bottom
 * @param {string} left
 * @param {boolean} inline, converts block element to inline block
 */
 export const Spacing = styled.div`
 ${(p) => p.top && `margin-top: ${p.theme.spacing[p.top]}`};
 ${(p) => p.right && `margin-right: ${p.theme.spacing[p.right]}`};
 ${(p) => p.bottom && `margin-bottom: ${p.theme.spacing[p.bottom]}`};
 ${(p) => p.left && `margin-left: ${p.theme.spacing[p.left]}`};
 ${(p) => p.inline && `display: inline-block;`}

 @media (max-width: ${(p) => p.theme.screen.sm}) {
   ${(p) =>
     p.hideOnSm &&
     `
     display: none;
   `}
 }
`;