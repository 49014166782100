import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Toggle from "react-toggle";
import roles from "config/roles";
import { useForm } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import { Row } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import { Input, Button } from "components/Form";

export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm,
}) {
  
  const { t } = useTranslation()
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  const [isAdmin, setIsAdmin] = useState(false)
  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = (data) => {
    const role = !!isAdmin ? roles['admin'] : roles['staff']
    onSubmit({...data, role});
  };

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        <Row>
            <Input
              {...register("email", { required: true })}
              placeholder={t('form.email')}
              type="email"
              aria-invalid={errors.email ? "true" : "false"}
          />
            <Input
              {...register("name")}
              placeholder={t('form.user.name')}
            />
            
        </Row>
        <Row>
        
            <Toggle
              checked={isAdmin}
              id="is-admin"
              onChange={() => setIsAdmin(!isAdmin)}
            />
            <label htmlFor="is-admin">{t("form.label.isAdmin")}</label>
         
        </Row>
        <Spacing bottom={"lg"} />
        <Button type="submit">{buttonLabel}</Button>
      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
