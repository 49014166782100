import styled from "styled-components";

export const PaginationContainer = styled.ul`
  list-style: none;
  padding: 0;
`;

export const PaginationList = styled.li`
  display: inline-block;
  a {
    margin:5px ;
    display: block;
    padding: 10px 20px;
    background: ${props=>props.current ? '#000' : 'lightgrey'} ;
    color: ${props=>props.current ? 'white' : 'black'} ;
    text-decoration: none;
    border-radius: 4px;
    transition: background 0.2s ease-out;
    
    &:hover,
    &:focus {
      background: #000;
      color: #fff;
    }
    
    &:focus {
      outline: 0;
    }
  }
`;