import {  fetchit } from 'lib'

import {
    API_PLACE
} from 'config/api'


export function createPlace(data, accessToken) {
    return fetchit(API_PLACE, data, 'POST', accessToken)
}

export function editPlace(data, placeId, accessToken) {
    return fetchit(API_PLACE+'/'+placeId, data, 'PATCH', accessToken)
}

export function getPlacesByStaffId({userId, accessToken}) {
    return fetchit(API_PLACE+'/staff/'+userId, null, 'GET', accessToken)
}

export function getPlaceById({placeId, accessToken}) {
    return fetchit(API_PLACE+'/'+placeId, null, 'GET', accessToken)
}

export function addStaffToPlace(placeId, data, accessToken) {
    return fetchit(API_PLACE+'/'+placeId+'/staff', data, 'PATCH', accessToken)
}

export function removeStaffFromPlace(placeId, staffId, accessToken) {
    return fetchit(API_PLACE+'/'+placeId+'/'+staffId, null, 'DELETE', accessToken)
}

export function geocode({ address, city, zipcode, country, accessToken }) {
    if(!address || !country || !city ) return false
    return fetchit(API_PLACE+'/geocode?address='+address+'&zipcode='+zipcode+'&country='+country+'&city='+city, null, 'GET', accessToken)
}