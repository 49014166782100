import Layout from "components/Layout";
import Map from "components/Map";

export default function App() {
  return (
    <Layout>
      <Map latitude="35.15475" longitude="-5.2321" height={"100%"} />
    </Layout>
  );
}
