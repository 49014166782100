import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "components/ProtectedRoute";
import AuthRoutes, { path as authPath } from "./auth";
import NotFoundRoutes, { path as notFoundPath } from "./404";
import PublicRoutes, { path as publicPath } from "./public";
//🔒 protected routes
import LocationsRoutes, { path as locationsPath } from "./locations";
import MediaRoutes, { path as mediaPath } from "./media";
import UserRoutes, { path as userPath } from "./users";
import MapRoutes, { path as mapPath } from "./mapView";

export default function RoutesComponent() {
  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
        theme="colored"
      />
      <BrowserRouter>
        <Fragment>
          <Routes>
            <Route exact path={authPath} element={<AuthRoutes />} />
            <Route exact path={publicPath} element={<PublicRoutes />} />
            <Route element={<ProtectedRoute />}>
              {/**🔒 Protected routes */}
              <Route exact path={locationsPath} element={<LocationsRoutes />} />
              <Route exact path={mediaPath} element={<MediaRoutes />} />
              <Route exact path={userPath} element={<UserRoutes />} />
              <Route exact path={mapPath} element={<MapRoutes />} />
              {/**🔒 End of Protected routes */}
            </Route>
            <Route path={notFoundPath} element={<NotFoundRoutes />} />
          </Routes>
        </Fragment>
      </BrowserRouter>
    </>
  );
}
