export const FRONT_END_AUTH_REFRESH_TOKENS =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:3000/auth/refresh-tokens"
    : "https://cockpit.wakati.io/auth/refresh-tokens";
export const API_BASE_URL =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:4040/v1/"
    : "https://api.wakati.io/v1/";
export const UPLOAD_CDN = "https://concierge.fra1.cdn.digitaloceanspaces.com/";
export const DEFAULT_DOC_URL =
  "https://fra1.digitaloceanspaces.com/team.marrakech.io/1662056408707.jpg";
export const API_AUTH_LOGIN = API_BASE_URL + "auth/login";
export const API_AUTH_LOGOUT = API_BASE_URL + "auth/logout";
export const API_AUTH_REFRESH_TOKENS = API_BASE_URL + "auth/refresh-tokens";
export const API_AUTH_AUTHORIZE = API_BASE_URL + "auth/authorize";
export const API_PLACE = API_BASE_URL + "location";
export const API_STAFF = API_PLACE + "/staff";
export const API_UPLOAD = API_BASE_URL + "upload";
export const API_USERS = API_BASE_URL + "users";
