import { fetchit } from 'lib'
import {
    API_AUTH_LOGIN,
    API_AUTH_LOGOUT,
    API_AUTH_REFRESH_TOKENS,
    FRONT_END_AUTH_REFRESH_TOKENS
} from 'config/api'


export function login(email) {
    return fetchit(API_AUTH_LOGIN, { email, redirectURI: FRONT_END_AUTH_REFRESH_TOKENS },'POST') 
}

export function refreshToken(refreshToken) {
    return fetchit(API_AUTH_REFRESH_TOKENS, { refreshToken },'POST')
}

export function logout(refreshToken) {
    return fetchit(API_AUTH_LOGOUT, { refreshToken },'POST')
}