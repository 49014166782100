import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: white ;

`;
export const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 320px;
  position: relative;

`;

export const RightSide = styled.div`
    height: 100vh;
    @media (max-width: ${(p) => p.theme.screen.sm}) {
        display: none;
    }
`

export const Error = styled.div`
    position: absolute ;
    margin: 0 auto;
    width: 100% ;
`

export const Wrapper = styled.div`
  color: ${(props) => (props.error ? "red" : "black")};
`;
